import React from "react"
import Helmet from "react-helmet"
import { graphql } from "gatsby"
import { FormattedMessage } from "react-intl"
import Layout from "../components/Layout"
import TechTag from "../components/TechTag"

import GooglePlayButton from "../images/google-play-button.png"
import AppStoreButton from "../images/app-store-button.png"
import DesignCirclePartImage from "../images/mask@3x.png"
import DesignCornerImage from "../images/rectangle-md.svg"
import DesignCube from "../components/DesignCube"
import AppDevThumbnail from "../components/thumbnails/AppDevThumbnail"
import UiUxThumbnail from "../components/thumbnails/UiUxThumbnail"

const CaseStudyTemplate = ({
  title,
  mainDescription,
  listImage,
  challengeTitle,
  challengeDescription,
  challengeAchievement,
  challengeAchievementDescription1,
  challengeAchievementDescription2,
  solutionTitle,
  solutionDescription,
  solutionHighlight,
  solutionAdditionalInfo,
  solutionAdditionalInfoHighlight,
  solutionImage,
  resultDescription,
  resultImage,
  resultItems,
  downloadTitle,
  helmet,
}) => {
  const bgClassNames = ["bg-green-400", "bg-green-500", "bg-green-800"]
  return (
    <div>
      {helmet || ""}
      <div className="relative lg:flex container mx-auto pt-12 px-10 lg:px-32">
        <img
          src={DesignCirclePartImage}
          alt=""
          className="absolute w-24 transform rotate-180"
          style={{ left: "50%", top: "5px" }}
        />
        <img
          src={DesignCirclePartImage}
          alt=""
          className="absolute w-48"
          style={{ right: "0", bottom: "-90px" }}
        />
        <div className="self-center flex-1">
          <h1 className="font-semibold text-6xl tracking-wide text-gray-900 leading-none pr-48">
            {title}
          </h1>
          <p className="font-light text-lg text-gray-500 mt-10 lg:pr-24">
            {mainDescription}
          </p>
          <p className="mt-10 mb-10">
            <TechTag>Kotlin</TechTag>
            <TechTag>Swift</TechTag>
          </p>
        </div>
        <div className="lg:flex-1 md:w-2/3 lg:w-auto">
          <img
            src={listImage.childImageSharp.fixed.src}
            alt=""
            className="-mb-8"
          />
        </div>
      </div>

      {/* CHALLENGE BLOCK */}
      <div className="" style={{ backgroundColor: "#f2f2f2" }}>
        <div className="container mx-auto pt-24 pb-24 md:pb-0 px-10 lg:px-32">
          <h2 className="font-semibold text-3xl mb-12">{challengeTitle}</h2>
          <p className="text-gray-500 pb-12">{challengeDescription}</p>

          {/* CHALLENEGE ACHIEVEMENTS */}
          <div className="flex flex-col md:flex-row">
            <div className="flex-none bg-gray-900 text-white uppercase leading-10 p-12 md:w-1/3 md:mr-24 pb-16 mb-12 md:mb-0">
              {challengeAchievement}
            </div>
            <div className="flex-col">
              <div className="text-gray-500">
                {challengeAchievementDescription1}
              </div>
              <div className="text-gray-500 mt-8">
                {challengeAchievementDescription2}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* SOLUTION BLOCK */}
      <div className="container mx-auto py-32 pb-4 px-10 lg:px-32">
        <div className="md:flex flex-row">
          <div className="flex-1">
            <h2 className="font-semibold text-3xl mb-12">{solutionTitle}</h2>
            <p className="text-gray-500">{solutionDescription}</p>
            <p className="font-bold m-20 relative">
              <img
                src={DesignCornerImage}
                alt=""
                className="absolute w-12"
                style={{ left: "-5rem", top: "-3rem" }}
              />
              <b>{solutionHighlight}</b>
              <DesignCube
                style={{
                  position: "absolute",
                  right: "-1rem",
                  bottom: "-3rem",
                }}
              />
            </p>
          </div>
          <div className="flex-1">
            <img
              src={solutionImage.childImageSharp.fixed.src}
              alt=""
              className="w-2/3 self-center mx-auto"
            />
          </div>
        </div>
        <div className="md:flex flex-row my-10 pt-16 border-solid border-t-16 border-gray-300">
          <p className="flex flex-1 mb-10 md:mb-0 md:pr-20 text-gray-500">
            {solutionAdditionalInfo}
          </p>
          <p className="flex flex-1 py-20 md:pl-24 relative">
            <img
              src={DesignCornerImage}
              alt=""
              className="absolute w-12"
              style={{ left: "0", top: "0" }}
            />
            <b>{solutionAdditionalInfoHighlight}</b>
            <DesignCube
              style={{ position: "absolute", right: "0", bottom: "2rem" }}
            />
          </p>
        </div>
      </div>

      {/* RESULT BLOCK */}
      <div className="" style={{ backgroundColor: "#f2f2f2" }}>
        <div className="relative container mx-auto py-32 px-10 lg:px-32 md:flex flex-row">
          <div className="flex flex-1 md:pr-4">
            <img
              src={resultImage.childImageSharp.fixed.src}
              alt=""
              className="self-center"
            />
          </div>
          <div className="flex flex-1 flex-col">
            <p className="text-gray-500 md:pl-16">{resultDescription}</p>
            <ul className="my-10 flex flex-row">
              {resultItems.map((item, i) => {
                const bgClassName = bgClassNames[i]
                const [number, ...words] = item.split(" ")
                const description = words.join(" ")
                return (
                  <li
                    key={i}
                    className={`flex flex-col flex-1 ${bgClassName} text-white text-center p-4`}
                  >
                    <b className="text-2xl mb-2">{number}</b>
                    {description}
                  </li>
                )
              })}
            </ul>
            <p className="text-gray-500 md:pl-16">{downloadTitle}</p>
            <div className="mt-12 flex flex-row md:pl-16">
              <a href="https://play.google.com/store/apps/details?id=lv.balta.app">
                <img
                  src={GooglePlayButton}
                  alt="Google Play"
                  className="h-12"
                />
              </a>
              <a href="https://apps.apple.com/lv/app/balta/id1475076952">
                <img
                  src={AppStoreButton}
                  alt="App Store"
                  className="h-12 ml-4"
                />
              </a>
            </div>
          </div>
          <img
            src={DesignCirclePartImage}
            alt=""
            className="absolute w-48"
            style={{ right: "10rem", bottom: "-2rem" }}
          />
        </div>
      </div>

      {/* USED TECHNOLOGIES BLOCK */}
      <div className="container mx-auto py-32 px-10 lg:px-32">
        <h3 className="text-center font-semibold text-2xl mb-12">
          <FormattedMessage id="case_study.tech_used" values={{ app: title }} />
        </h3>
        <div className="md:flex md:flex-row justify-center">
          <AppDevThumbnail className="md:w-1/3" />
          <UiUxThumbnail className="md:w-1/3" />
        </div>
      </div>
    </div>
  )
}

const BaltaCaseStudy = ({ data, location }) => {
  const { markdownRemark: project } = data

  return (
    <Layout location={location}>
      <CaseStudyTemplate
        helmet={
          <Helmet>
            <title>{`${project.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${project.frontmatter.mainDescription}`}
            />
          </Helmet>
        }
        {...project.frontmatter}
      />
    </Layout>
  )
}

export default BaltaCaseStudy

export const pageQuery = graphql`
  query baltaProjectByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        title
        mainDescription
        listImage {
          childImageSharp {
            fixed(width: 1000, quality: 75) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        challengeTitle
        challengeDescription
        challengeAchievement
        challengeAchievementDescription1
        challengeAchievementDescription2
        solutionTitle
        solutionDescription
        solutionHighlight
        solutionAdditionalInfo
        solutionAdditionalInfoHighlight
        solutionImage {
          childImageSharp {
            fixed(width: 700, quality: 75) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        resultDescription
        resultImage {
          childImageSharp {
            fixed(width: 700, quality: 75) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        resultItems
        downloadTitle
      }
    }
  }
`
